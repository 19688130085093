
import axios from 'axios';
import {DFTLNGCODE, URL as NEWURL} from '../constants';
import {VENDORID} from '../constants';

export default function RegisterFormRequest(formData) {

    const requestOptions = {
        method: 'POST',
        headers: { 'Accept': 'application/json' },
        body: JSON.stringify({
            "serviceId": 1117,
            "vendorId": VENDORID,
            "lang": localStorage.getItem('rcml-lang')??DFTLNGCODE,
            "shopUserFirstname": formData["shopUserFirstname"],
            "shopUserSurname": formData["shopUserSurname"],
            "shopUserEmail": formData["shopUserEmail"],
            "shopUserPhone": formData["shopUserPhone"],
            "customerName": formData["customerName"],
            "customerVATNr": formData["customerVATNr"],
            "customerTaxNr": formData["customerTaxNr"],
            "customerAddress": formData["customerAddress"],
            "customerPostCode": formData["customerPostCode"],
            "customerCity": formData["customerCity"],
            "customerCountry": formData["customerCountry"],
            "customerCountryCode": formData["customerCountryCode"],
            "customerAdrInfo": formData["customerAdrInfo"],
            "shippingAddress": formData["shippingAddress"]??"",
            "shippingPostCode": formData["shippingPostCode"]??"",
            "shippingAdrName": formData["shippingAdrName"]??"",
            "shippingCity": formData["shippingCity"]??"",
            "shippingCountry": formData["shippingCountry"]??"",
            "shippingCountryCode": formData["shippingCountryCode"]??"",
            "shippingAdrInfo": formData["shippingAdrInfo"]??"",
            "requestNote" : formData["requestNote"]??"",
            "companyDesc" : formData["companyDesc"]??"",

            "docType1": formData["docType1"],
            "docType2": formData["docType2"],
            "docType3": formData["docType3"],

        })
    };

    const fetchData = async () => {
        try {
            const response = await fetch(NEWURL, requestOptions);
            const json = await response.json();
            return json;
        } catch (error) {
            console.log("error", error);
        }
    };

    return fetchData();
}

export async function upload_folders(file, fileName, vendor) {

    try {
        // FormData oluşturuyoruz (dosya yükleme için ideal bir yöntem)
        const formData = new FormData();
        formData.append("file", file); // Dosyayı ekliyoruz
        formData.append("fileName", fileName); // Dosya adını ekliyoruz
        formData.append("vendor", base64Encode(vendor)); // Vendor bilgisini ekliyoruz

        // Axios ile POST isteği
        const response = await axios.post("https://pay.mobiserver.de:4240/webshop-registrant", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        return response.data; // Yanıtı döndürüyoruz
    } catch (error) {
        console.error("Upload sırasında hata:", error);
        throw error; // Hata durumunda hata fırlatıyoruz
    }
}

export function base64Encode(text) {
    if (typeof window === 'undefined') {
      // Node.js ortamı
      return Buffer.from(text, 'utf-8').toString('base64');
    } else {
      // Tarayıcı ortamı
      return btoa(new TextEncoder().encode(text).reduce((data, byte) => data + String.fromCharCode(byte), ''));
    }
  }

  // Decode (Orijinal ID'ye dön)
  export function base64Decode(base64) {
    if (!base64) {
      return '';
    }

    if (typeof window === 'undefined') {
      // Node.js ortamı
      return Buffer.from(base64, 'base64').toString('utf-8');
    } else {
      // Tarayıcı ortamı
      return new TextDecoder().decode(
        Uint8Array.from(atob(base64), (char) => char.charCodeAt(0))
      );
    }
  }
